import React from "react";
import Carousel from "react-multi-carousel";
import leftIcon from "../../assets/object/left.png";
import rightIcon from "../../assets/object/right.png";
import { BlogCard, SkeltonCard } from "../../components/Cards";

const responsive = {
  "2xl": {
    breakpoint: { max: 4000, min: 1500 },
    items: 4,
  },
  xl: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1500, min: 1280 },
    items: 3,
  },
  lg: {
    breakpoint: { max: 1280, min: 1024 },
    items: 3,
  },
  md: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  sm: {
    breakpoint: { max: 768, min: 676 },
    items: 2,
    arrows: false,
  },
  xs: {
    breakpoint: { max: 676, min: 400 },
    items: 2,
    arrows: false,
  },
  xxs: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    arrows: false,
  },
};
const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className=" tw-top-1/2 position-absolute  w-100  tw-hidden sm:tw-block">
      <button
        className=" btn position-absolute -tw-left-12 px-0 shadow-none"
        onClick={() => previous()}
      >
        <img height="44px" width="45px" className="img-fluid " src={leftIcon} />
      </button>
      <button
        className="btn position-absolute -tw-right-12 px-0 shadow-none"
        onClick={() => next()}
      >
        <img
          height="44px"
          width="45px"
          className="img-fluid "
          src={rightIcon}
        />
      </button>
    </div>
  );
};
const testimonials = [
  {
    id: 1,
    title:
      "Smoothie-Fastenkur: Die bessere Alternative zur traditionellen Saftkur",
    body: '<h3>Die Verbindung zwischen Fasten und Gesundheit: Eine Einführung in Fastenkuren und ihre Vorteile</h3><p>Hast du dich schon einmal gefragt, warum Fastenkuren in letzter Zeit so an Beliebtheit gewonnen haben? Diese Methode der Ernährung bietet viele Vorteile, die weit über das bloße Abnehmen hinausgehen. Fastenkuren wie die beliebte "Saftkur", egal ob als "3-Tage-Saftkur" oder "5-Tage-Saftkur", werden oft als eine Art Neuanfang für den Körper betrachtet.</p>',
    image: "202412171044Smoothie Fastenkur und Saftkur.png",
    is_featured: "1",
    status: "0",
    created_at: "2024-04-29T10:15:16.000000Z",
    updated_at: "2025-02-13T08:16:02.000000Z",
    short_text:
      "Entdecke, wie du mit unseren Smoothie-Fastenkuren deinen Körper „resetten“ und dein Wohlbefinden neu beleben kannst!",
  },
  {
    id: 2,
    title:
      "Ernährung als Schlüssel zur sportlichen Höchstleistung: Wie du mit der richtigen Ernährung deine Ziele erreichst",
    body: '<h3>Die Verbindung zwischen Fasten und Gesundheit: Eine Einführung in Fastenkuren und ihre Vorteile</h3><p>Hast du dich schon einmal gefragt, warum Fastenkuren in letzter Zeit so an Beliebtheit gewonnen haben? Diese Methode der Ernährung bietet viele Vorteile, die weit über das bloße Abnehmen hinausgehen. Fastenkuren wie die beliebte "Saftkur", egal ob als "3-Tage-Saftkur" oder "5-Tage-Saftkur", werden oft als eine Art Neuanfang für den Körper betrachtet.</p>',
    image: "2024121813382_Gesunde Ernährung und Sport.png",
    is_featured: "1",
    status: "0",
    created_at: "2024-04-29T10:23:09.000000Z",
    updated_at: "2025-02-13T08:00:28.000000Z",
    short_text:
      "Ernährung ist der Schlüssel zu sportlichen Höchstleistungen! Sie liefert Energie und unterstützt die Regeneration. Ernährung = Erfolg!",
  },
  {
    id: 3,
    title:
      "Dein Weg zur natürlichen Entgiftung: Wie Fastenkuren und Smoothies dein Wohlbefinden stärken",
    body: '<h3>Die Verbindung zwischen Fasten und Gesundheit: Eine Einführung in Fastenkuren und ihre Vorteile</h3><p>Hast du dich schon einmal gefragt, warum Fastenkuren in letzter Zeit so an Beliebtheit gewonnen haben? Diese Methode der Ernährung bietet viele Vorteile, die weit über das bloße Abnehmen hinausgehen. Fastenkuren wie die beliebte "Saftkur", egal ob als "3-Tage-Saftkur" oder "5-Tage-Saftkur", werden oft als eine Art Neuanfang für den Körper betrachtet.</p>',
    image: "20241218144232_Entgiftung mit gesunder Ernährung.png",
    is_featured: "1",
    status: "0",
    created_at: "2024-04-29T10:24:44.000000Z",
    updated_at: "2024-12-18T14:42:08.000000Z",
    short_text:
      "Bunter Mix, starke Wirkung! Eine abwechslungsreiche Ernährung mit frischem Obst und Gemüse stärkt dein Immunsystem und steigert die Leistungsfähigkeit. Entdecke, wie Farben mehr als nur dein Tellerbild verschönern.",
  },
  {
    id: 4,
    title:
      "Ernährung und Arthrose: Natürliche Ansätze zur Unterstützung deiner Gelenkgesundheit",
    body: '<h3>Die Verbindung zwischen Fasten und Gesundheit: Eine Einführung in Fastenkuren und ihre Vorteile</h3><p>Hast du dich schon einmal gefragt, warum Fastenkuren in letzter Zeit so an Beliebtheit gewonnen haben? Diese Methode der Ernährung bietet viele Vorteile, die weit über das bloße Abnehmen hinausgehen. Fastenkuren wie die beliebte "Saftkur", egal ob als "3-Tage-Saftkur" oder "5-Tage-Saftkur", werden oft als eine Art Neuanfang für den Körper betrachtet.</p>',
    image: "20241218145737 Ernährung bei Arthrose.png",
    is_featured: "1",
    status: "0",
    created_at: "2024-04-29T10:26:21.000000Z",
    updated_at: "2024-12-18T14:57:45.000000Z",
    short_text:
      "In unserem neuesten Blogpost tauchen wir in die Welt der entzündungshemmenden Ernährung und Lebensstilstrategien ein, die unsere Gelenke stärken und beweglich halten. Von der Kraft von Omega-3-Fettsäuren in fettem Fisch und Chia-Samen über die antioxidativen Vorteile von Beeren und grünem Blattgemüse bis hin zu den entzündungshemmenden Eigenschaften von Kurkuma",
  },
];

export default function BlogsCarousel({ data, isLoading }) {
  // const { isLoading, error, data } = useQuery({
  //   queryKey: ["testmonialsListing"],
  //   queryFn: getTestimonials,

  // });
  // console.log("Dtata ", data);
  return (
    <>
      <div className=" tw-relative">
        <div className=" tw-mx-auto 2xl:tw-pb-24 xl:tw-pb-20 tw-pb-16 ">
          <div className="tw-text-center tw-mb-12">
            <h2 className="tw-text-3xl tw-font-bold  tw-mt-4">
              Das sagen unsere Kunden
            </h2>
          </div>

          <div className="container">
            <div className="">
              <div className=" m-auto position-relative tw-overflow-hidden ">
                <Carousel
                  responsive={responsive}
                  itemclassName=""
                  autoPlay={false}
                  arrows={false}
                  renderButtonGroupOutside={true}
                  customButtonGroup={<ButtonGroup />}
                  infinite={true}
                  showDots={false}
                  containerClass=" tw-items-stretch "
                >
                  {isLoading || data?.length == 0
                    ? Array.from(Array(8))?.map((box, index) => {
                        return (
                          <div
                            key={index}
                            className="px-md-5"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          >
                            <SkeltonCard profileStyle={true} />
                          </div>
                        );
                      })
                    : data?.map((blog, index) => (
                        <div
                          key={index}
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          className="2xl:tw-px-4 xl:tw-px-3 tw-px-2 tw-h-full max-sm:tw-max-w-[100vw]"
                        >
                          <BlogCard data={blog} />
                        </div>
                      ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
