import React, { useLayoutEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getBlogById } from "../../services/BlogPages";
import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../../services/Adapter/customAxios";
import Loader from "../../components/common/Loader";
import { Helmet } from "react-helmet";
import { FABComponent } from "../../components/common/ShareButtons";
import BlogsCarousel from "./BlogsCarousel";
import { useTableOfContent } from "../../hooks/useTableofContent";
import moment from "moment";

export default function BlogDetail() {
  const { pathname } = useLocation();
  const { slug } = useParams();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);
  let id = slug?.split("_")[slug?.split("_")?.length - 1];
  const {
    isLoading: blogLoading,
    error: blogError,
    data,
  } = useQuery({
    queryKey: ["blogById", slug],
    queryFn: () => getBlogById(slug || 2),
  });
  let blogData = data?.data?.data;
  let next_title_slug = blogData?.next?.title?.split(" ")?.join("-");
  let nextSlug = next_title_slug?.concat(`_${blogData?.next?.id}`);

  let prev_title_slug = blogData?.previous?.title?.split(" ")?.join("-");
  let prevSlug = prev_title_slug?.concat(`_${blogData?.previous?.id}`);
  const { body, TOC } = useTableOfContent(blogData?.current?.body);
  // console.log("TOC ", TOC);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
        <title> {`Indivit | ${blogData?.current?.title}`} </title>
        <meta name="description" content={blogData?.current?.short_text} />
      </Helmet>
      {blogLoading && <Loader />}
      {/* {JSON.stringify(blogData)} */}
      <div className="tw-mx-auto tw-max-w-[1440px] ">
        <div className="tw-flex tw-flex-col  tw-mx-auto tw-overflow-hidden tw-rounded">
          <img
            src={
              baseURL + "blogs/" + blogData?.current?.image ||
              "https://source.unsplash.com/random/480x360"
            }
            alt=""
            className="tw-w-full tw-h-auto tw-max-w-6xl tw-mx-auto lg:tw-rounded-xl lg:tw-mt-36 md:tw-mt-32 tw-mt-28"
          />
          <div className=" tw-mx-auto tw-space-y-6 sm:tw-px-10 sm:tw-mx-12 md:tw-px-16 tw-px-5 ">
            <div className="tw-max-w-6xl tw-mx-auto lg:tw-px-6">
              <h1 className="tw-inline-block lg:tw-text-3xl md:tw-text-2xl sm:tw-text-xl lg:-tw-mt-10 -tw-mt-10 tw-font-bold tw-bg-white tw-w-full tw-shadow-lg tw-p-4 tw-rounded-lg tw-text-center ">
                {blogData?.current?.title}
              </h1>
            </div>
            {/* <div dangerouslySetInnerHTML={{ __html: TOC }}></div> */}
            {/* <div className=" tw-text-end">
              <ShareButtons />
            </div> */}
            {/* Share Component */}
            <FABComponent />
            {/* <div className="tw-space-y-2"> </div> */}
            {blogData?.current?.author_name && (
              <div className="tw-bg-white tw-rounded-lg tw-border tw-border-solid tw-border-gray-200 tw-flex tw-items-center tw-justify-between 2xl:tw-p-4 tw-p-3">
                <div className="tw-flex tw-items-center tw-gap-1">
                  <img
                    src={
                      blogData?.current?.icon
                        ? baseURL + "blogs/" + blogData?.current?.icon
                        : `https://ui-avatars.com/api/?background=random&size=150&name=${blogData?.current?.author_name}`
                    }
                    alt={blogData?.current?.author_name}
                    className="tw-rounded-full tw-w-12 tw-h-12"
                  />
                  <p className="text-xs dark:text-gray-600 mb-0 tw-font-bold">
                    {blogData?.current?.author_name}
                  </p>
                </div>
                <div className="tw-space-y-1">
                  <p className="tw-text-xs tw-font-bold tw-mb-0">
                    Veröffentlicht:{" "}
                    {moment(blogData?.current?.created_at).format(
                      "DD. MMMM YYYY"
                    )}
                  </p>
                  <p className="tw-text-xs tw-font-bold tw-mb-0">
                    Aktualisiert:{" "}
                    {moment(blogData?.current?.updated_at).format(
                      "DD. MMMM YYYY"
                    )}
                  </p>
                </div>
              </div>
            )}
            {blogData?.current?.description && (
              <div className="  lg:tw-rounded-md   tw-py-5 tw-max-w-3xl tw-mx-auto html-blog ">
                <div
                  dangerouslySetInnerHTML={{
                    __html: blogData?.current?.description,
                  }}
                ></div>
              </div>
            )}
            <div>
              <h1 className="tw-text-3xl">Inhaltsverzeichnis</h1>
              <ul className="tw-list-none">
                {TOC?.map((item, index) => (
                  <li key={index}>
                    <a
                      href={`#${item.id}`}
                      className="tw-text-gray-700 hover:tw-text-blue-600 tw-no-underline hover:tw-underline   "
                    >
                      <strong>
                        {">"}&nbsp;
                        {item.text}
                      </strong>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="  lg:tw-rounded-md  sm:tw-py-10 md:tw-py-16 tw-py-5 tw-max-w-3xl tw-mx-auto html-blog ">
              <p dangerouslySetInnerHTML={{ __html: body }}></p>
            </div>
          </div>
        </div>

        <div className=" md:tw-px-16 sm:tw-px-10 tw-px-5 ">
          {blogData?.current?.related_blogs?.length > 0 && (
            <BlogsCarousel data={blogData?.current?.related_blogs} />
          )}
          {/* <div className="">
            {" "}
            <Link
              to={`/blogs/${prevSlug}`}
              className="tw-flex tw-items-center tw-gap-4 tw-decoration-transparent tw-font-bold tw-text-black hover:tw-text-theme"
            >
              {blogData?.previous && (
                <img
                  height="auto"
                  width="31px"
                  className="img-fluid "
                  src={leftArrow}
                />
              )}
              <span className="sm:tw-block tw-hidden">
                {blogData?.previous?.title}
              </span>
              <span className="tw-block sm:tw-hidden">Previous Blog</span>
            </Link>
          </div>
          <div className="">
            <Link
              to={`/blogs/${nextSlug}`}
              className="tw-flex tw-items-center tw-gap-4 tw-decoration-transparent tw-text-end tw-font-bold tw-text-black hover:tw-text-theme"
            >
              <span className="sm:tw-block tw-hidden">
                {blogData?.next?.title}
              </span>
              <span className="tw-block sm:tw-hidden">Next Blog</span>
              {blogData?.next && (
                <img
                  height="auto"
                  width="31px"
                  className="img-fluid "
                  src={rightArrow}
                />
              )}{" "}
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}
