import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/common/Loader";
import { Helmet } from "react-helmet";
import BlogsHero from "./BlogsHero";
import { BlogCard } from "../../components/Cards";
import { getBlogsList } from "../../services/BlogPages";
import { PaginationTab } from "../../components/common/Pagination";
import { useLocation } from "react-router-dom";

export default function Blogs() {
  const { pathname } = useLocation();
  // console.log("About JSON ", AboutJSON);
  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, []);
  const [pageSchema, setPageSchema] = useState({
    currentPage: 1,
    search: "",
    limit: 12,
  });
  const [blogData, setBlogData] = useState({});
  const {
    isLoading: aboutLoading,
    error: aboutError,
    data,
  } = useQuery({
    queryKey: ["blogsList", pageSchema],
    queryFn: () => getBlogsList(pageSchema),
  });
  useEffect(() => {
    if (data?.data) {
      // console.log("Blog Data ", data);
      setBlogData(data?.data?.data);
    }
  }, [data]);

  // let blogData = data?.data?.data;
  const handlePageSchema = (key, value) => {
    setPageSchema((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
        <title> {`Indivit | Smoothie Wissen`} </title>
      </Helmet>
      {aboutLoading && <Loader />}
      <BlogsHero
        data={{
          title: "Smoothie Wissen",
          description:
            "Entdecken Sie eine köstliche Auswahl an frisch gemixten Smoothies bei unserem Online-Händler. Passen Sie jede Mischung mit unserem Mixer individuell an Ihren Geschmack an. Von belebenden Fruchtmischungen bis hin zu cremigen Genüssen – unsere Smoothies sind ein köstliches Abenteuer, das auf Sie wartet!",
        }}
      />{" "}
      {/* <div className="flx-hero-about !tw-h-auto">
        <p className="text-center pb-2">
          Entdecken Sie eine köstliche Auswahl an frisch gemixten Smoothies bei
          unserem Online-Händler. Passen Sie jede Mischung mit unserem Mixer
          individuell an Ihren Geschmack an. Von belebenden Fruchtmischungen bis
          hin zu cremigen Genüssen – unsere Smoothies sind ein köstliches
          Abenteuer, das auf Sie wartet!
        </p>
      </div> */}
      {/* {JSON.stringify(blogData?.data)} */}
      <div className="tw-max-w-7xl tw-mt-10 tw-px-4 tw-mx-auto tw-grid 2xl:tw-grid-cols-4 md:tw-grid-cols-3 tw-grid-cols-2 tw-gap-4">
        {blogData?.data?.map((blog, index) => {
          return (
            <div key={index}>
              <BlogCard data={blog} />
            </div>
          );
        })}
      </div>
      <PaginationTab data={blogData} handlePageSchema={handlePageSchema} />
    </div>
  );
}
